import { TrackAndTrace } from '../../../api/TrackAndTrace';


export const consultar_reserva = async (serialNumber) => {

    const params = new URLSearchParams();

    params.append('airlinePrefix', 279);
    params.append('serialNumber', serialNumber);
    params.append('carrierCode', 'B6');

    try {

        const respuesta = await TrackAndTrace.get('csp/track-and-trace/v1/airwaybill', {params});         
        const { airwaybill } = respuesta.data;

        return airwaybill;
        
    } catch (error) {

        console.log(error)
        return false;
        
    }
}