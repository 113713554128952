
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, List, Typography } from 'antd';
import { RightSquareOutlined } from '@ant-design/icons';
import { GetBooking } from './helpers/MethodsBooking';


export const MostrarBookings = ({bookings}) => {

    const [lista, setLista] = useState(bookings.bookings);   
    const navigate = useNavigate();
    
    const verBooking = async (reserva) => {    

        const Booking = await GetBooking(reserva);

        if(Booking){
            navigate(`/formulario/booking?r=${JSON.stringify(Booking)}`); 
        }
    }
   
    return(
        <>
        <Divider orientation="left"></Divider>
        <List
            header={<div>Header</div>}
            footer={<div>Footer</div>}
            bordered
            dataSource={lista}
            renderItem={(item) => (

                <List.Item key={item.airWaybillIdentifier.serial}>

                    <Typography.Text mark></Typography.Text> 
                        {item.carrier.code}
                            &nbsp;
                        {item.airWaybillIdentifier.airlinePrefix}-{item.airWaybillIdentifier.serial}
                            &nbsp;
                        {item.origin.code} - {item.destination.code}
                            &nbsp;
                        <strong>
                            {item.bookingStatus}
                        </strong> 
                        {' '}
                        <RightSquareOutlined  onClick={ () => verBooking(item.airWaybillIdentifier.serial)}/>
                                
                </List.Item>
            )}
        />
        
       
     </>
    )

    
}



