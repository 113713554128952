import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, message } from 'antd';
import { Bookings } from '../../api/Bookings';
import { MostrarBookings } from './MostrarBookings';
import { Spinner } from '../ui/Spinner';
import { ErrorAvailabilityFligth } from './ErrorAvailabilityFligth';
import { ApiContext } from '../../context/ApiContext';
import { GetBooking } from './helpers/MethodsBooking';


export const ListBookings = () => {

    const [auth, guardarAuth] = useContext(ApiContext);  
    const [bookings, setBookings ] = useState([]);  

    const [ mostrar, setMostrar] = useState(false);  
    const [ showSpinner, setShowSpinner] = useState(false);

    const [ error, setError] = useState(false);
    const navigate = useNavigate();

    const allBookings = () => {

        const { agents } = auth;
        const agent =Object.keys(agents);

        const url = `/v2/bookings?accountNumbers=${agent.toString()}`; 
        consultar(url);        
    }

    const onFinish = async ({number}) => {

        if( !number ){
            message.error('Awb number can not empty');
            return
        }

        setShowSpinner(true);
        const consultaBooking = await GetBooking(number);

        if(consultaBooking){
            navigate(`/formulario/booking?r=${JSON.stringify(consultaBooking)}`); 
        }
     
        setShowSpinner(false);
        setError(true);       
        
    };
    
    const consultar = async (url) => {   
                
        try {

            setError(false);
            setShowSpinner(true);         
            
            const { data: {_embedded}, headers } = await Bookings.get(url, {
                headers: {
                    sessionid: localStorage.getItem('token')
                }
            }); 
            
            setBookings(_embedded);                              
            setShowSpinner(false);
            setMostrar(true);            
            
        } catch (error) {
            console.log(error)
            setShowSpinner(false);
            setError(true);
        }
        
    }

    useEffect( () => {}, [bookings]);

    return(
        <>
            <Form onFinish={onFinish}  layout="inline"  size='small'>

                <Form.Item
                     label="Awb"                                                                                          
                >                    
                    <input 
                        type="text" 
                        value='279' 
                        disabled style={{width: '50px'}}       
                    />

                </Form.Item>

                <Form.Item
                     label="Number"
                     name="number" 
                     rules={[
                        {len: 8, message: 'AWB Number must be 8 digits'}
                    ]}
                    hasFeedback                                                         
                >                    
                    <input type="text"/>
                </Form.Item>

                <Form.Item>
                    <Button 
                        type="primary" 
                        htmlType="submit" 
                        style={{backgroundColor: '#2981C4', color: 'white'}}
                    >
                        Search
                    </Button>
                </Form.Item>

            </Form>
            <br />
                <Button 
                    type="primary" 
                    onClick={allBookings}
                    style={{backgroundColor: '#2981C4', color: 'white'}}
                >
                  All
                </Button>

                {
                    (showSpinner) &&  <div> <Spinner /> </div>                                 
                }

                {
                    (error && !showSpinner) && <ErrorAvailabilityFligth />      
                }

                {
                    (mostrar) && <MostrarBookings bookings={bookings}/>
                }
            
        </>
        
        )
        
    }
                
