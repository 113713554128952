import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Form, Input, Row, Col } from 'antd';
import { DetalleReserva } from './DetalleReserva';
import { ApiContext } from '../../context/ApiContext';
import { Spinner } from '../ui/Spinner';
import { ErrorMsg } from '../Modal/ErrorMsg';
import { consultar_reserva } from './helpers/consultar_reserva';


export const Reserva = () => {

    const [ auth, guardarAuth] = useContext(ApiContext);
    const { token } = auth;
    
    const [ booking, setBooking] = useState({});
    const [ showSpinner, setShowSpinner] = useState(false);

    const [ showError, setShowError] = useState(false);
    const location = useLocation(); 
    
    const onFinish = ({serialNumber}) => {
        consultar(serialNumber);    
    };

    const consultar = async (serialNumber) => {

        setBooking({});

        setShowError(false);  
        setShowSpinner(true); 

        const respuesta = await consultar_reserva(serialNumber);

        setBooking(respuesta);   
        setShowSpinner(false);

        if(!respuesta){
            setShowSpinner(false);     
            setShowError(true);  
        }
        
    }

    const Share = () => {

        const url = window.location.origin; // obtengo la url del servidor
        const awbParams = `/awb-track?airlinePrefix=279&serialNumber=${booking.serialNumber}`       

        if (navigator.share) {
            navigator.share({
              title: 'Aeronex Cargo',
              text: 'Look this Awb',
              url: `${url}${awbParams}`,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
          }
    }

    if(showSpinner){
        return <Spinner />
    }
      
    return(
        <>     
            {
                (showError) && <ErrorMsg  ver={true}/>
            }  

            <Row gutter={[32, 16]}>
                <Form
                    name="horizontal"
                    layout="inline"
                    size='small'  
                    labelCol={{
                        span: (window.innerWidth > 720 ? 20 : 8),
                    }}
                    wrapperCol={{
                        span: 10,
                    }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={onFinish}                
                    autoComplete="off"
                >

                 <Col xs={24} sm={16} md={8} lg={6} xl={6}>

                    <Form.Item  
                        label="AWB Number"                  
                        name="airlinePrefix"                       
                        wrapperCol={{
                            span: 10,
                        }}            
                    >
                        <Input 
                            type='text'
                            defaultValue='279'    
                            disabled                                 
                        />

                    </Form.Item>

                </Col>

               <span style={{marginLeft: (window.innerWidth > 720 ? 8 : 15)}}> – </span>

                <Col xs={24} sm={12} md={8} lg={6} xl={8}>

                    <Form.Item  
                        name="serialNumber" 
                        wrapperCol={{
                            span: 20,
                        }}  
                        rules={[
                            {
                                required: true,
                                message: 'Please input AWB Number',
                            },
                            {
                                pattern: /^[0-9]+$/,
                                message: 'Include only numbers.',
                            }, 
                            {len: 8, message: 'AWB must be 8 digits'}
                        ]}
                        hasFeedback                 
                    >
                        <Input  
                            type='text'
                            placeholder='number'                                           
                        />

                     </Form.Item>

                </Col>                
           
                <Form.Item className='btn'>

                    <Button 
                        type="primary" 
                        htmlType="submit" 
                        style={{
                            backgroundColor: '#1D2758', 
                            color: 'white',
                             marginLeft: (window.innerWidth > 720 ? -15 : 10)
                        }} 
                    > 
                        Submit
                    </Button>

                </Form.Item>
              
                    <Button 
                        type="primary" 
                        onClick={Share}
                        disabled={Object.entries(booking).length === 0}
                        style={{
                            backgroundColor: '#1D2758', 
                            color: 'white', 
                            marginLeft: (window.innerWidth > 720 ? -15 : 10)
                        }} 
                    > 
                        Share
                    </Button>
            </Form>        
        </Row>

            {
               (Object.entries(booking).length > 0) && <DetalleReserva booking={booking} />           
            } 
       </>
    )
}