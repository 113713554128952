import { Update_Cancel_Booking } from '../../../api/Bookings';

export const GetBooking = async ( number) => {

    const url = `/v2/bookings/279-${number}`; 

         try {

            const { data, headers } = await Update_Cancel_Booking.get(url, {
                headers: {
                    sessionid: localStorage.getItem('token'),
                }
            });  
                     
            return data;           
            
         } catch (error) {
            console.log(error)
            return false;
         }

}

export const UpdateBooking = async (id, reserva, etag) => {

    try {

        const actualizar = await Update_Cancel_Booking.put(`v2/bookings/${id}`, reserva);
        return true;
        
    } catch (error) {
        console.log(error)
        return false;        
    }
}



export const CancelBooking = async (id) => {

    try {
            
        const peticion = await Update_Cancel_Booking.post(`v2/bookings/${id}/cancellation-requests`, null);   
        return true;
        
    } catch (error) {
        console.log(error)
        return false;
    }
}