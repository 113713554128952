import axios from 'axios'
//  apikey: 'EnbX12j02DDHFrAoqjaq3FIkmTGncrrk',


export const Bookings = axios.create({
   // baseURL: 'https://api-gateway.champ.aero/booking', // cargo spot
    baseURL: 'https://acsapi-portal.azurewebsites.net/booking',
}); 

export const Update_Cancel_Booking = axios.create({
    baseURL: 'https://api-portal.aeronexcargo.com/booking',
    //baseURL: 'https://api-gateway.champ.aero/booking',              
});

Update_Cancel_Booking.interceptors.request.use(

    (config) => {

        delete config.headers['Accept'];
        delete config.headers['Content-Type'];

        const etag =  localStorage.getItem('etag');
        const token = localStorage.getItem('token');

        if(etag ) {
            config.headers['If-Match'] = etag;
        }
        config.headers['sessionid'] = token;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

export const BAgent = axios.create({
    baseURL: 'https://acsapi-portal.azurewebsites.net/acs/forms/v1'
}); 