
import React, { useEffect, useMemo } from 'react';
import { notification } from 'antd';

const Context = React.createContext({
  name: 'Default',
});

export const MsgAvailability = () => {

  const [api, contextHolder] = notification.useNotification();

  useEffect( () => {
    openNotification('topRight')
  }, []);

  const openNotification = (placement) => {
    api.info({
      message: 'No Flights Available',
      description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };
  
  const contextValue = useMemo(
    () => ({
      name: 'No Data',
    }),
    [],
  );
  return (
    <Context.Provider value={contextValue}>
      {contextHolder}      
    </Context.Provider>
  );
};
